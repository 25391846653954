<!-- <label><b>Buscar cotización:</b></label> -->
<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <input name="CustomerFilter" id="CustomerFilter" class="form-control" placeholder="{{ 'clientsearch' | translate }}" 
        (keyup)="onFilterCustomer($event.target.value)">
    </div>
    <div class="form-group col-md-4" style="margin-left: 380px; margin-top: 5px;">
        <input name="CustomerSKUFilter" id="CustomerSKUFilter" class="form-control" placeholder="{{ 'symbolsearch' | translate }}" 
        (keyup)="onFilterCustomerSKU($event.target.value)">
    </div>
</div>
<table class="table table-hover">
    <thead class="thead-light">
        <th>{{ 'quote' | translate }}</th>
        <th>{{ 'date' | translate }}</th>
        <th>{{ 'customer' | translate }}</th>
        <th>{{ 'simbolcustomer' | translate }}</th>
        <th hidden = "true">Destino</th>
        <th style="text-align: right;">{{ 'averageorder' | translate }} (cja)</th>
        <th hidden = "true">Freight Cost</th>
        <th hidden = "true">Costo MXN/MPzas</th>
        <th style="text-align: right;">{{ 'priceusd' | translate }}</th>
        <th style="text-align: right;">{{ 'margin' | translate }}</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" routerLink="/quote" (click)="onNewOrEdit()"><i class="fa fa-plus-square"></i>&nbsp; {{ 'btnnew' | translate }}</a>
        </th>
    </thead>
    <tbody>
        
        <tr *ngFor="let item of paginatedData; let index=index">
            <th (click)="openForEdit(item.QuoteID)">{{item.QuoteID}}</th>
            <td>{{item.QuoteDate | date:"dd MMM y"}}</td>
            <td>{{item.Customer}}</td>
            <td>{{item.CustomerSKU}}</td>
            <td hidden = "true">{{item.Destiny}}</td>
            <td style="text-align: right;">{{item.AverageOrder}}</td>
            <td hidden = "true">{{item.FreightCost}}</td>
            <td hidden = "true">{{item.CostMBox}}</td>
            <td style="text-align: right;">{{item.PriceMBox}}</td>
            <!-- <td style="text-align: right;">{{item.ProfitWithFreight}}</td> -->
            <td *ngIf="item.ProfitWithFreight<=8" style="text-align: right; background-color: red; color:white; font-weight: bold;">{{item.ProfitWithFreight}}</td>
            <td *ngIf="item.ProfitWithFreight>8 && item.ProfitWithFreight<15" style="text-align: right; background-color: orange; font-weight: bold;">{{item.ProfitWithFreight}}</td>
            <td *ngIf="item.ProfitWithFreight>=15" style="text-align: right; background-color: rgb(53, 172, 26); font-weight: bold;">{{item.ProfitWithFreight}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="openForEdit(item.QuoteID)"><i class="fa fa-pencil"></i></a>
                <a class="btn btn-sm btn-danger text-white ml-1" (click)="onQuoteDelete(item.QuoteID)"><i class="fa fa-trash"></i></a>
                <a class="btn btn-sm btn-light text-white ml-1" (click)="onQuoteRep(item.QuoteID)"><i class="fa fa-file-pdf-o" style="color: red"></i></a>
                <a class="btn btn-sm btn-light text-white ml-1" (click)="onQuoteBOM(item.QuoteID)"><i class="fa fa-list" style="color: darkblue"></i></a>
                <a class="btn btn-sm btn-light text-white ml-1" (click)="onQuoteProfit(item.QuoteID)"><i class="fa fa-bar-chart" style="color: green"></i></a>
            </td>
        </tr>
    </tbody>
</table>
<!--código de la paginación-->
<nav>
    <ul class="pagination"> <!--controla el recuadro de anterior-->
        <li class="page-item" style="cursor: pointer;">
            <a class="page-link"
            [ngClass]="{disabled:currentPage ===1 }" 
            (click)="pageClicked(currentPage - 1)">{{ 'previous' | translate }}</a>
        </li>
        <li (click)="pageClicked(page)"
            *ngFor="let page of pages" 
            class="page-item" 
            [ngClass]="{ active: page == currentPage }">
            <a class="page-link" href="javascript:void(0)">{{ page }}</a><!--se pocisiona en la pagina y activa el cuadrito.-->
        </li>
        <li class="page-item" style="cursor: pointer;"> <!--controla el recuadro de siguiente-->
            <a class="page-link" 
            [ngClass]="{ disabled: currentPage === totalPages }" 
            (click)="pageClicked(currentPage + 1)">{{ 'next' | translate }}</a>
        </li>
    </ul>
</nav>
