<h4 class="display-5">{{ 'inksmain' | translate }}</h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-3">
            <label>{{ 'idink' | translate }}</label>
            <input name="InkID" #InkID="ngModel" [(ngModel)]="inkService.inkList.InkID" class="form-control"
            readonly>  
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'gcmi' | translate }}</label>
            <input name="InkGCMI" id="InkGCMI" #InkGCMI="ngModel" [(ngModel)]="inkService.inkList.InkGCMI" 
            class="form-control" style="text-transform:uppercase">  
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'description' | translate }}</label>
            <input name="InkName" id="InkName" #InkName="ngModel" [(ngModel)]="inkService.inkList.InkName" 
            class="form-control">  
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'costink' | translate }}</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">{{ 'costusdkg' | translate }}</div>
                </div> 
                <input name="Cost" #Cost="ngModel" [(ngModel)]="inkService.inkList.Cost" 
                class="form-control" type="number" min="0">
            </div>
        </div>
    </div>  
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i>&nbsp; {{ 'btnsave' | translate }}</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i>&nbsp; {{ 'btnclose' | translate }}</button>
    </div>
</form>