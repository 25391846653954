<h4 class="display-5">{{ 'updatetitle' | translate }} </h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-2">
            <label>{{ 'idboard' | translate }}</label>
            <input name="BoardID" #BoardID="ngModel" [(ngModel)]="boardCostService.boardCostList.BoardID" class="form-control"
            readonly>  
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-3">
            <label>{{ 'papermm2' | translate }}</label>
            <input name="BoardPaperCostMM2" id="BoardPaperCostMM2" #BoardPaperCostMM2="ngModel" [(ngModel)]="boardCostService.boardCostList.BoardPaperCostMM2" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'paperton' | translate }}</label>
            <input name="BoardPaperCostTon" id="BoardPaperCostTon" #BoardPaperCostTon="ngModel" [(ngModel)]="boardCostService.boardCostList.BoardPaperCostTon" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'adhesivemm2' | translate }}</label>
            <input name="StarchCostMM2" id="StarchCostMM2" #StarchCostMM2="ngModel" [(ngModel)]="boardCostService.boardCostList.StarchCostMM2" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'adhesiveton' | translate }}</label>
            <input name="StarchCostTon" id="StarchCostTon" #StarchCostTon="ngModel" [(ngModel)]="boardCostService.boardCostList.StarchCostTon" 
            class="form-control" type="number" min="0">
        </div>
    </div>  
    <div class="form-row">
        <div class="form-group col-md-3">
            <label>{{ 'coatingmm2' | translate }}</label>
            <input name="CoatingCostMM2" id="CoatingCostMM2" #CoatingCostMM2="ngModel" [(ngModel)]="boardCostService.boardCostList.CoatingCostMM2" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'coatington' | translate }}</label>
            <input style="width: 30px; height: 30px;" type="checkbox"  id="CoatingCostTon" #CoatingCostTon="ngModel" [(ngModel)]="boardCostService.boardCostList.CoatingCostTon" 
             min="0">
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'watermm2' | translate }}</label>
            <input name="WaterCostMM2" id="WaterCostMM2" #WaterCostMM2="ngModel" [(ngModel)]="boardCostService.boardCostList.WaterCostMM2" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'waterton' | translate }}</label>
            <input name="WaterCostTon" id="WaterCostTon" #WaterCostTon="ngModel" [(ngModel)]="boardCostService.boardCostList.WaterCostTon" 
            class="form-control" type="number" min="0">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-3">
            <label>{{ 'oauxiliaresmm2' | translate }}</label>
            <input name="OtherAuxCostMM2" id="OtherAuxCostMM2" #OtherAuxCostMM2="ngModel" [(ngModel)]="boardCostService.boardCostList.OtherAuxCostMM2" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'oauxiliareston' | translate }}</label>
            <input name="OtherAuxCostTon" id="OtherAuxCostTon" #OtherAuxCostTon="ngModel" [(ngModel)]="boardCostService.boardCostList.OtherAuxCostTon" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'gforkliftmm2' | translate }}</label>
            <input name="ForkliftGasCostMM2" id="ForkliftGasCostMM2" #ForkliftGasCostMM2="ngModel" [(ngModel)]="boardCostService.boardCostList.ForkliftGasCostMM2" 
            class="form-control" type="number" min="0">
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'gforkliftton' | translate }}</label>
            <input name="ForkliftGasCostTon" id="ForkliftGasCostTon" #ForkliftGasCostTon="ngModel" [(ngModel)]="boardCostService.boardCostList.ForkliftGasCostTon" 
            class="form-control" type="number" min="0">
        </div>
    </div>
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i>&nbsp; {{ 'btnsave' | translate }}</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i>&nbsp; {{ 'btnclose' | translate }}</button>
    </div>
</form>
 

