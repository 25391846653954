<!--h3>Buscar Cliente:</h3-->
<label><b>{{ 'formcustomer' | translate }}</b></label>
<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <input name="CustomerNameFilter" id="CustomerNameFilter" class="form-control" placeholder="{{ 'clientsearch' | translate }}" 
        (keyup)="onFilterCustomerName($event.target.value)">
    </div>
</div>

<table class="table table-hover">
    <thead class="thead-light">
        <th>{{ 'idcustomer' | translate }}</th>
        <th>{{ 'rfc' | translate }}</th>
        <th>{{ 'companyname' | translate }}</th>
        <th hidden = "true" >Nombre Comercial</th>
        <th>{{ 'contact' | translate }}</th>
        <th hidden = "true">ContactPosition</th>
        <th hidden = "true">Country</th>
        <th hidden = "true">State</th>
        <th hidden = "true">Address</th>
        <th hidden = "true">ZipCode</th>
        <th style="text-align: center;">{{ 'phone' | translate }}</th>
        <th>{{ 'email' | translate }}</th>
        <th style="text-align: center;">{{ 'category' | translate }}</th>
        <th style="text-align: center;">{{ 'margin' | translate }}</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditCustomerItem(null, dataCustomer.dataCustomer.CustomerID)"><i class="fa fa-plus-square"></i>&nbsp;{{ 'btnnew' | translate }}</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of paginatedData; let i=index;">
            <th (click)="AddOrEditCustomerItem(((currentPage-1)*itemsPerPage)+i, item.CustomerID)">{{item.CustomerID}}</th>
            <td>{{item.CustomerRFC}}</td>
            <td>{{item.CustomerName}}</td>
            <td hidden = "true" >{{item.CustomerTradeName}}</td>
            <td>{{item.ContactName}}</td>
            <td hidden = "true">{{item.ContactPosition}}</td>
            <td hidden = "true">{{item.CountryID}}</td>
            <td hidden = "true">{{item.StateID}}</td>
            <td hidden = "true">{{item.Address}}</td>
            <td hidden = "true">{{item.ZipCode}}</td>
            <td style="text-align: center;">{{item.Telephone}}</td>
            <td>{{item.Email}}</td>
            <td style="text-align: center;">{{item.CustomerType}}</td>
            <td style="text-align: center;">{{item.TargetProfitability}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditCustomerItem(((currentPage-1)*itemsPerPage)+i, item.CustomerID)"><i class="fa fa-pencil"></i></a>
                <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteCustomerItem(item.CustomerID)"><i class="fa fa-trash"></i></a>
            </td>
        </tr>
    </tbody>
</table>
<!--código de la paginación-->
<nav>
    <ul class="pagination"> <!--controla el recuadro de anterior-->
        <li class="page-item" style="cursor: pointer;">
            <a class="page-link"
            [ngClass]="{disabled:currentPage ===1 }" 
            (click)="pageClicked(currentPage - 1)">{{ 'previous' | translate }}</a>
        </li>
        <li (click)="pageClicked(page)"
            *ngFor="let page of pages" 
            class="page-item" 
            [ngClass]="{ active: page == currentPage }">
            <a class="page-link" href="javascript:void(0)">{{ page }}</a><!--se pocisiona en la pagina y activa el cuadrito.-->
        </li>
        <li class="page-item" style="cursor: pointer;"> <!--controla el recuadro de siguiente-->
            <a class="page-link" 
            [ngClass]="{ disabled: currentPage === totalPages }" 
            (click)="pageClicked(currentPage + 1)">{{ 'next' | translate }}</a>
        </li>
    </ul>
</nav>