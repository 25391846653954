import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Auxiliary } from 'src/app/shared/auxiliary.model';
import { AuxiliaryService } from 'src/app/shared/auxiliary.service';
import { AuxiliaryItemsComponent } from './auxiliary-items.component';

@Component({
  selector: 'app-auxiliary',
  templateUrl: './auxiliary.component.html',
  styles: []
})
export class AuxiliaryComponent implements OnInit {
   //paginación declaracion de variables
  itemsPerPage=12;
  currentPage=1;
  totalPages = 0;
  totalItems=0;
  data1;
  totales;
  pages: number [] = [];
  @Output() onClick: EventEmitter<number> = new EventEmitter();//captura el evento click y se mueva

  constructor(
    private dialog: MatDialog, 
    public dataAuxiliary: AuxiliaryService, 
    private router: Router, 
    private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dataAuxiliary.getAuxiliaryList().then(res => this.dataAuxiliary.dataAuxiliary = res as Auxiliary);
  }

    //paginación funciones
  get paginatedData(){    
    this.data1= this.dataAuxiliary.dataAuxiliary;
    console.log(this.data1);  
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.totalItems = this.data1.length;
      return this.data1.slice(start,end);
  }

  ngAfterViewInit(){
    this.updateItems();
  }

  updateItems(){
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({length: this.totalPages}, (_, i) => i + 1);
       //console.log(this.pages);
  }

  pageClicked(page: number){
    if(page > this.totalPages) return;
    this.onClick.emit(page);
    this.changePage(page);
  }

  changePage(page: number){
  this.currentPage=page;
  }

  refreshList(){
    //this.dataBoard.dataBoard.BoardIDFilter = "";
    document.getElementById("AuxiliaryIDFilter").focus();
    var formAuxiliaryID = <HTMLInputElement> document.getElementById("AuxiliaryIDFilter");
    //formBoardID.value = ''
    if ( formAuxiliaryID.value == '' || formAuxiliaryID.value == undefined){
      this.dataAuxiliary.getAuxiliaryList().then(res => this.dataAuxiliary.dataAuxiliary = res as Auxiliary);
    } else {
      this.dataAuxiliary.getAuxiliaryListId(formAuxiliaryID.value).then(res => this.dataAuxiliary.dataAuxiliary = res as Auxiliary);
    }
  };

  AddOrEditAuxiliaryItem(auxiliaryItemIndex, AuxiliaryID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="60%";
    dialogConfig.height ="50%";
    dialogConfig.data = { auxiliaryItemIndex, AuxiliaryID };
    this.dialog.open(AuxiliaryItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  onDeleteAuxiliaryItem(auxiliaryID: number){
    if (confirm('Estas seguro de quere borrar el registro?')){
      this.dataAuxiliary.deleteAuxiliary(auxiliaryID).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Corrugated Quote.');
      });
    };
  };

  onFilterAuxiliaryName(auxiliaryName: string){
    this.dataAuxiliary.getAuxiliaryListId(auxiliaryName).then(res => this.dataAuxiliary.dataAuxiliary = res as Auxiliary);
    this.totalItems = this.data1.length;
    this.currentPage = 1;
    this.updateItems();
  };

  getformFocus(formID: string) {
    document.getElementById(formID).focus();
    var formAuxiliaryID = <HTMLInputElement> document.getElementById(formID);
    formAuxiliaryID.value = ''
  };

}
