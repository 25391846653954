
<!--h3>Buscar por color:</h3--> 
<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <label><b>{{ 'inktitle' | translate }}</b></label>
        <input name="InkIDFilter" id="InkIDFilter" class="form-control" placeholder="{{ 'inksearch' | translate }}" 
        (keyup)="onFilterInkName($event.target.value)">
    </div>
</div>
<table class="table table-hover">
    <thead class="thead-light">
        <th>{{ 'idink' | translate }}</th>
        <th>{{ 'gcmi' | translate }}</th>
        <th>{{ 'description' | translate }}</th>
        <th style="text-align: right;">{{ 'inkcost' | translate }}</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditInkItem(null, dataInk.dataInk.InkID)"><i class="fa fa-plus-square"></i>&nbsp; {{ 'btnnew' | translate }}</a>
        </th>
    </thead>
    <tbody>
        <!--tr *ngFor="let item of dataInk.dataInk; let i=index;"-->
        <tr *ngFor="let item of paginatedData; let i=index">
            <th (click)="AddOrEditInkItem(((currentPage-1)*itemsPerPage)+i, item.InkID)">{{item.InkID}}</th>
            <td>{{item.InkGCMI}}</td>
            <td>{{item.InkName}}</td>
            <td style="text-align: right;">{{item.Cost}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditInkItem(((currentPage-1)*itemsPerPage)+i, item.InkID)"><i class="fa fa-pencil"></i></a>
                <!-- <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteInkItem(item.InkID)"><i class="fa fa-trash"></i></a> -->
            </td>
        </tr>
    </tbody>
</table>
<!--código de la paginación-->
<nav>
    <ul class="pagination"> <!--controla el recuadro de anterior-->
        <li class="page-item" style="cursor: pointer;">
            <a class="page-link"
            [ngClass]="{disabled:currentPage ===1 }" 
            (click)="pageClicked(currentPage - 1)">{{ 'previous' | translate }}</a>
        </li>
        <li (click)="pageClicked(page)"
            *ngFor="let page of pages" 
            class="page-item" 
            [ngClass]="{ active: page == currentPage }">
            <a class="page-link" href="javascript:void(0)">{{ page }}</a><!--se pocisiona en la pagina y activa el cuadrito.-->
        </li>
        <li class="page-item" style="cursor: pointer;"> <!--controla el recuadro de siguiente-->
            <a class="page-link" 
            [ngClass]="{ disabled: currentPage === totalPages }" 
            (click)="pageClicked(currentPage + 1)">{{ 'next' | translate }}</a>
        </li>
    </ul>
</nav>

