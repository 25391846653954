<!--h3>Buscar:</h3-->
<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <label><b>{{ 'auxtitle' | translate }}</b></label>
        <input name="AuxiliaryIDFilter" id="AuxiliaryIDFilter" class="form-control" placeholder="{{ 'auxsearch' | translate }}" 
        (keyup)="onFilterAuxiliaryName($event.target.value)">
    </div>
</div>
<table class="table table-hover">
    <thead class="thead-light">
        <th>{{ 'auxid' | translate }}</th>
        <th>{{ 'auxname' | translate }}</th>
        <th>{{ 'auxconsumption' | translate }}</th>
        <th>{{ 'auxunit' | translate }}</th>
        <th style="text-align: right;">{{ 'auxcost' | translate }}</th>
        <th>{{ 'auxunit' | translate }}</th>
        <th style="text-align: right;">{{ 'auxwaste' | translate }} %</th>
        <th style="text-align: center;">{{ 'auxlife' | translate }}</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditAuxiliaryItem(null, dataAuxiliary.dataAuxiliary.AuxMatID)"><i class="fa fa-plus-square"></i>&nbsp; {{ 'btnnew' | translate }}</a>
        </th>
    </thead>
    <tbody>
        <!--tr *ngFor="let item of dataAuxiliary.dataAuxiliary; let i=index;"-->
        <tr *ngFor="let item of paginatedData; let i=index">
            <th (click)="AddOrEditAuxiliaryItem(((currentPage-1)*itemsPerPage)+i, item.AuxiliaryID)">{{item.AuxMatID}}</th>
            <td>{{item.AuxMatName}}</td>
            <td style="text-align: right;">{{item.AuxMatRatCon}}</td>
            <td>{{item.AuxMatUniCon}}</td>
            <td style="text-align: right;">{{item.AuxMatCost}}</td>
            <td>{{item.AuxMatCostUni}}</td>
            <td style="text-align: right;">{{item.AuxMatWaste}}</td>
            <td style="text-align: center;">{{item.AuxLifeTime}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditAuxiliaryItem(((currentPage-1)*itemsPerPage)+i, item.AuxMatID)"><i class="fa fa-pencil"></i></a>
                <!-- <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteAuxiliaryItem(item.AuxMatID)"><i class="fa fa-trash"></i></a> -->
            </td>
        </tr>
    </tbody>
</table>

<!--código de la paginación-->
<nav>
    <ul class="pagination"> <!--controla el recuadro de anterior-->
        <li class="page-item" style="cursor: pointer;">
            <a class="page-link"
            [ngClass]="{disabled:currentPage ===1 }" 
            (click)="pageClicked(currentPage - 1)">{{ 'previous' | translate }}</a>
        </li>
        <li (click)="pageClicked(page)"
            *ngFor="let page of pages" 
            class="page-item" 
            [ngClass]="{ active: page == currentPage }">
            <a class="page-link" href="javascript:void(0)">{{ page }}</a><!--se pocisiona en la pagina y activa el cuadrito.-->
        </li>
        <li class="page-item" style="cursor: pointer;"> <!--controla el recuadro de siguiente-->
            <a class="page-link" 
            [ngClass]="{ disabled: currentPage === totalPages }" 
            (click)="pageClicked(currentPage + 1)">{{ 'next' | translate }}</a>
        </li>
    </ul>
</nav>
