<h4 class="display-5">{{ 'wtitle' | translate }} </h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-1">
            <label>{{ 'wid1' | translate }}</label>
            <input name="MachineID" #MachineID="ngModel" [(ngModel)]="workstationService.workstationList.MachineID" 
            class="form-control" type="text">  
        </div>
        <div class="form-group col-md-3">
            <label>{{ 'wname' | translate }}</label>
            <input name="MachineName" #MachineName="ngModel" [(ngModel)]="workstationService.workstationList.MachineName" 
            class="form-control">  
        </div>
        <div class="form-group col-md-2">
            <label>{{ 'wtype' | translate }}</label>
            <input name="Type" id="Type" #Type="ngModel" [(ngModel)]="workstationService.workstationList.Type" 
            class="form-control">  
        </div>  
        <div class="form-group col-md-2">
            <label>{{ 'wtrim' | translate }}</label>
            <div class="input-group">
                <input name="TrimCorrugator" id="TrimCorrugator" #TrimCorrugator="ngModel" [(ngModel)]="workstationService.workstationList.TrimCorrugator" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">cm</div>
                </div> 
            </div>
        </div>       
        <div class="form-group col-md-2">
            <label>{{ 'wefficiency' | translate }}</label>
            <div class="input-group">
                <input name="OperatingEfficiency" id="OperatingEfficiency" #OperatingEfficiency="ngModel" [(ngModel)]="workstationService.workstationList.OperatingEfficiency" 
                type="number" min="0" class="form-control" style="text-align: right;">
                <div class="input-group-prepend">
                    <div class="input-group-text">%</div>
                </div>
            </div>
        </div>
    </div>  
    <div class="form-row">
        <form class="border border-primary rounded form-group col-md-7" style="font-weight: bold; font-size: small;">{{ 'winformation' | translate }}
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label class="small">{{ 'wmanufac' | translate }}</label>
                    <input name="ManufacturedBy" #ManufacturedBy="ngModel" [(ngModel)]="workstationService.workstationList.ManufacturedBy" 
                    class="form-control">  
                </div>
                <div class="form-group col-md-3">
                    <label class="small">{{ 'wbrand' | translate }}</label>
                    <input name="Brand" #Brand="ngModel" [(ngModel)]="workstationService.workstationList.Brand" class="form-control">  
                </div>
                <div class="form-group col-md-3">
                    <label class="small">{{ 'wmodel' | translate }}</label>
                    <input name="Model" id="Model" #Model="ngModel" [(ngModel)]="workstationService.workstationList.Model" 
                    class="form-control">  
                </div>
                <div class="form-group col-md-2">
                    <label class="small">{{ 'wink' | translate }}</label>
                    <input name="NumColor" id="NumColor" #NumColor="ngModel" [(ngModel)]="workstationService.workstationList.NumColor" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
            </div>
        </form>
        <form class="border border-primary rounded form-group col-md-5" style="font-weight: bold; font-size: small;">{{ 'wmaxmin' | translate }}
            <div class="form-row">
                <div class="form-group col-md-3">
                    <label class="small">{{ 'wwidthminimun' | translate }}</label>
                    <div class="input-group">
                        <input name="WidthMin" id="WidthMin" #WidthMin="ngModel" [(ngModel)]="workstationService.workstationList.WidthMin" 
                        class="form-control" type="number" min="0" style="text-align: right;">
                        <div class="input-group-prepend">
                            <div class="input-group-text">cm</div>
                        </div> 
                    </div>  
                </div>
                <div class="form-group col-md-3">
                    <label class="small">{{ 'wwidthmaximun' | translate }}</label>
                    <div class="input-group">
                        <input name="WidthMax" id="WidthMax" #WidthMax="ngModel" [(ngModel)]="workstationService.workstationList.WidthMax" 
                        class="form-control" type="number" min="0" style="text-align: right;">
                        <div class="input-group-prepend">
                            <div class="input-group-text">cm</div>
                        </div> 
                    </div>  
                </div>
                <div class="form-group col-md-3">
                    <label class="small">{{ 'wlongminimun' | translate }}</label>
                    <div class="input-group">
                        <input name="LenghtMin" id="LenghtMin" #LenghtMin="ngModel" [(ngModel)]="workstationService.workstationList.LenghtMin" 
                        class="form-control" type="number" min="0" style="text-align: right;">
                        <div class="input-group-prepend">
                            <div class="input-group-text">cm</div>
                        </div> 
                    </div>  
                </div>
                <div class="form-group col-md-3">
                    <label class="small">{{ 'wlongmaximun' | translate }}</label>
                    <div class="input-group">
                        <input name="LenghtMax" id="LenghtMax" #LenghtMax="ngModel" [(ngModel)]="workstationService.workstationList.LenghtMax" 
                        class="form-control" type="number" min="0" style="text-align: right;">
                        <div class="input-group-prepend">
                            <div class="input-group-text">cm</div>
                        </div> 
                    </div>  
                </div>
            </div>
        </form>
    </div>

    <hr>
    <h5 class="display-7">{{ 'wspeed' | translate }} </h5>
    <br>
    <div class="form-row">
        <form class="border border-primary rounded form-group col-md-4" style="font-weight: bold; font-size: small;">{{ 'wregisterhigh' | translate }}
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label class="small">{{ 'wboardhigh' | translate }}</label>
                    <input name="SpeedRegHigDenHig" id="SpeedRegHigDenHig" #SpeedRegHigDenHig="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegHigDenHig" 
                        class="form-control" type="number" min="0" style="text-align: right;">
                </div>
                <div class="form-group col-md-4">
                    <label class="small">{{ 'wboardmedium' | translate }}</label>
                    <input name="SpeedRegHigDenMed" id="SpeedRegHigDenMed" #SpeedRegHigDenMed="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegHigDenMed" 
                    class="form-control" type="number" min="0"style="text-align: right;" >
                </div>
                <div class="form-group col-md-4">
                    <label class="small">{{ 'wboardlow' | translate }}</label>
                    <input name="SpeedRegHigDenLow" id="SpeedRegHigDenLow" #SpeedRegHigDenLow="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegHigDenLow" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
            </div>
        </form>
        <form class="border border-primary rounded form-group col-md-4" style="font-weight: bold; font-size: small;">{{ 'wregistermedium' | translate }}
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label class="small">{{ 'wboardhigh' | translate }}</label>
                    <input name="SpeedRegMedDenHig" id="SpeedRegMedDenHig" #SpeedRegMedDenHig="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegMedDenHig" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
                <div class="form-group col-md-4">
                    <label class="small">{{ 'wboardmedium' | translate }}</label>
                    <input name="SpeedRegMedDenMed" id="SpeedRegMedDenMed" #SpeedRegMedDenMed="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegMedDenMed" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
                <div class="form-group col-md-4">
                    <label class="small">{{ 'wboardlow' | translate }}</label>
                    <input name="SpeedRegMedDenLow" id="SpeedRegMedDenLow" #SpeedRegMedDenLow="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegMedDenLow" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
            </div>
        </form>
        <form class="border border-primary rounded form-group col-md-4" style="font-weight: bold; font-size: small;">{{ 'wregisterlow' | translate }}
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label class="small">{{ 'wboardhigh' | translate }}</label>
                    <input name="SpeedRegLowDenHig" id="SpeedRegLowDenHig" #SpeedRegLowDenHig="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegLowDenHig" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
                <div class="form-group col-md-4">
                    <label class="small">{{ 'wboardmedium' | translate }}</label>
                    <input name="SpeedRegLowDenMed" id="SpeedRegLowDenMed" #SpeedRegLowDenMed="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegLowDenMed" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
                <div class="form-group col-md-4">
                    <label class="small">{{ 'wboardlow' | translate }}</label>
                    <input name="SpeedRegLowDenLow" id="SpeedRegLowDenLow" #SpeedRegLowDenLow="ngModel" [(ngModel)]="workstationService.workstationList.SpeedRegLowDenLow" 
                    class="form-control" type="number" min="0" style="text-align: right;">
                </div>
            </div>
        </form>
    </div>
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i>&nbsp; {{ 'btnsave' | translate }}</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i>&nbsp; {{ 'btnclose' | translate }}</button>
    </div>
</form>
 
