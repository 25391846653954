<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <label><b>{{ 'destinytitle' | translate }}</b></label>
        <input name="DestinyNameFilter" id="DestinyNameFilter" class="form-control" placeholder="{{ 'destinysearch' | translate }}" 
        (keyup)="onFilterDestinyName($event.target.value)">
    </div>
</div>

<table class="table table-hover">
    <thead class="thead-light">
        <th>{{ 'destinyid' | translate }}</th>
        <th hidden="true">País</th>
        <th hidden="true">Estado</th>
        <th>{{ 'destinycity' | translate }}</th>
        <th>{{ 'destinymunicipality' | translate }}</th>
        <th>{{ 'destinyzipcode' | translate }}</th>
        <th style="text-align: right;">{{ 'destinydistance' | translate }}</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditDestinyItem(null, dataDestiny.dataDestiny.DestinyID)"><i class="fa fa-plus-square"></i>&nbsp; {{ 'btnnew' | translate }}</a>
        </th>
    </thead>
    <tbody>
        <!--tr *ngFor="let item of dataDestiny.dataDestiny; let i=index;"-->
        <tr *ngFor="let item of paginatedData; let i=index">
            <th (click)="AddOrEditDestinyItem(((currentPage-1)*itemsPerPage)+i, item.DestinyID)">{{item.DestinyID}}</th>
            <td hidden="true">{{item.CountryID}}</td>
            <td hidden="true">{{item.StateID}}</td>
            <td>{{item.City}}</td>
            <td>{{item.Municipality}}</td>
            <td>{{item.ZipCode}}</td>
            <td style="text-align: right;">{{item.Distance}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditDestinyItem(((currentPage-1)*itemsPerPage)+i, item.DestinyID)"><i class="fa fa-pencil"></i></a>
                <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteDestinyItem(item.DestinyID)"><i class="fa fa-trash"></i></a>
            </td>
        </tr>
    </tbody>
</table>
<!--código de la paginación-->
<nav>
    <ul class="pagination"> <!--controla el recuadro de anterior-->
        <li class="page-item" style="cursor: pointer;">
            <a class="page-link"
            [ngClass]="{disabled:currentPage ===1 }" 
            (click)="pageClicked(currentPage - 1)">{{ 'previous' | translate }}</a>
        </li>
        <li (click)="pageClicked(page)"
            *ngFor="let page of pages" 
            class="page-item" 
            [ngClass]="{ active: page == currentPage }">
            <a class="page-link" href="javascript:void(0)">{{ page }}</a><!--se pocisiona en la pagina y activa el cuadrito.-->
        </li>
        <li class="page-item" style="cursor: pointer;"> <!--controla el recuadro de siguiente-->
            <a class="page-link" 
            [ngClass]="{ disabled: currentPage === totalPages }" 
            (click)="pageClicked(currentPage + 1)">{{ 'next' | translate }}</a>
        </li>
    </ul>
</nav>
