<!---->
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <!--<a class="navbar-brand" href="#">Corrugated Quote App.</a>-->
  
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" 
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" style="margin-right: 80px;">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="/quotehome">{{ 'menu1' | translate }} <span class="sr-only">(current)</span></a>
      </li>
      <!--li class="nav-item">
        <a class="nav-link" href="#">Mí Cuenta</a>
      </li-->
      <li class="nav-item">
        <a class="nav-link" href="/quote" >{{ 'menu2' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/quotes">{{ 'menu3' | translate }}</a>
      </li>
      <li class="nav-item dropdown"><!--*ngIf="uid=='d7f04f23-ff18-4e59-a205-9f832f032781'"-->
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" translate>
          menu4
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="/customer">{{ 'submenu4.0' | translate }}</a>
          <a class="dropdown-item" href="/supplier">{{ 'submenu4.1' | translate }}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/paper">{{ 'submenu4.2' | translate }}</a>
          <a class="dropdown-item" href="/board">{{ 'submenu4.3' | translate }}</a>
          <a class="dropdown-item" href="/ink">{{ 'submenu4.4' | translate }}</a>
          <a class="dropdown-item" href="/auxiliary">{{ 'submenu4.5' | translate }}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/trucktype">{{ 'submenu4.6' | translate }}</a>
          <a class="dropdown-item" href="/pallet">{{ 'submenu4.7' | translate }}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/freight">{{ 'submenu4.8' | translate }}</a>
          <a class="dropdown-item" href="/destiny">{{ 'submenu4.9' | translate }}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/workstation">{{ 'submenu4.10' | translate }}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/costrate">{{ 'submenu4.11' | translate }}</a>
        </div>
      </li>
      <!--
      <li class="nav-item dropdown">//</li> *ngIf="uid=='d7f04f23-ff18-4e59-a205-9f832f032782'">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ 'menu4' | translate }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="/customer">{{ 'submenu4.0' | translate }}</a>
          //a class="dropdown-item" href="/supplier">Proveedores</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/paper">Papeles</a>
          <a class="dropdown-item" href="/board">Resistencias</a>
          <a class="dropdown-item" href="/ink">Tintas</a>
          <a class="dropdown-item" href="/auxiliary">Auxiliares</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/trucktype">Unidades de Transporte</a>
          <a class="dropdown-item" href="/pallet">Tarimas</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/freight">Fletes</a>
          <a class="dropdown-item" href="/destiny">Destinos</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/workstation">Máquinas</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/costrate">Tarifas</a>//
        </div>
      </li>
    -->
      <li class="nav-item">
        <!--a class="nav-link" href="/" (click)="logout()">Cerrar Sesión</a-->
        <button type="button" class="nav-link" (click)="logout()" translate>menu5</button>
      </li>
      <!--li class="nav-item">
        <a class="nav-link" href="/contact">Contacto</a>
      </li-->
     <!-- <li class="nav-item">
        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
      </li>-->
    </ul>
    <!--<form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form>
    -->
    <ul class="navbar-nav">
      <!--li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" translate>
          menu6
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="switchLanguage('en')">{{ 'submenu6.1' | translate }}</a>
          <a class="dropdown-item" (click)="switchLanguage('es')">{{ 'submenu6.2' | translate }}</a>
        </div>
      </li-->
      <li class="nav-item">
          <select class="form-select" (change)="switchLanguage($event)" name="languaje" id="languaje">
            <option value="">{{ 'menu6' | translate }}</option>
            <option [selected]="lang==='en'" value="en">{{ 'submenu6.1' | translate }}</option>
            <option [selected]="lang==='es'" value="es">{{ 'submenu6.2' | translate }}</option>
          </select>
      </li>
      
    </ul>
  </div>
</nav>
<div class="jumbotron jumbotron-fluid">
  <div class="container" style="max-width: 90%;">
    <div class="row justify-content-end">
      <div class="col-md-12 offset-md-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<!--
<div class="jumbotron jumbotron-fluid">
  <h3 class="display-4 text-center"><img src="/assets/img/box.png" width="60px" height="60px"> Corrugated Quote App.</h3>
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-md-12 offset-md-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
-->