import { Component } from '@angular/core';
import { QuoteService } from './shared/quote.service';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
//import * as from '../assets/i18n/en.json';
//import  as prod from '../assets/i18n/es.json';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
//arreglo con los datos obtenidos de azure
type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
  //styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Corrugated Quote';
  public uid;
  lang:string ='';
  profile!: ProfileType;

  constructor(public service: QuoteService,
    private authservice: MsalService,
    private http: HttpClient,
    private translateService: TranslateService
  ) {
      translateService.addLangs(['en', 'es']);
      translateService.setDefaultLang('en');
      //this.translateService.setDefaultLang(this.lang)
      //translateService.use('en');
      //this.lang = (translateService.getBrowserLang() || "en");
      //alert(this.lang);
      //localStorage.getItem('lang') || 'en';
      //alert(this.lang);
      /*if(this.lang==""){
        translateService.use(translateService.getBrowserLang() || "en");
      }else{
        if(this.lang=="es"){
          translateService.use('es');
        }else{
          translateService.use('en');
        }
      }*/
  }
    
    
    ngOnInit(){
      this.getProfile();
      this.lang = localStorage.getItem('lang') || this.translateService.getBrowserLang();
      this.translateService.use(this.lang);
    }
  
    getProfile() {
      this.http.get(GRAPH_ENDPOINT)
        .subscribe(profile => {
          this.profile = profile;
          //console.log(profile['id']);
          this.uid = profile['id'];
        });
    }
    
    logout(){
      localStorage.setItem('lang','');
      this.authservice.logoutRedirect({
        //postLogoutRedirectUri: 'https://localhost:4200/quotehome'
        postLogoutRedirectUri: 'https://cotizador.cartonero.digital/quotehome'
      });
    }

    switchLanguage(language: any): void {
      const lang1 = language.target.value;
      localStorage.setItem('lang', lang1);
      this.translateService.use(lang1);
    }
  /* onAppQuoteBox(){
    this.service.viewBoxSheetBotton = true;
  }; */

  //onAppQuoteSheet(){
  //  this.service.qBoxOrSheet = false;
  //};
};


