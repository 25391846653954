import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { exit, off } from 'process';
import { Board } from 'src/app/shared/board.model';
import { BoardService } from 'src/app/shared/board.service';
import { Ect } from 'src/app/shared/ect.model';
import { EctService } from 'src/app/shared/ect.service';
import { Flute } from 'src/app/shared/flute.model';
import { FluteService } from 'src/app/shared/flute.service';
import { Paper } from 'src/app/shared/paper.model';
import { PaperService } from 'src/app/shared/paper.service';
import { Supplier } from 'src/app/shared/supplier.model';
import { SupplierService } from 'src/app/shared/supplier.service';

@Component({
  selector: 'app-board-items',
  templateUrl: './board-items.component.html',
  styles: []
})
export class BoardItemsComponent implements OnInit {
  supplierList : Supplier [];
  paperList: Paper [];
  fluteList: Flute [];
  ectList: Ect [];
  maxAltBoard:number;
  isValid: boolean = true;
  dateBoard: Date = new Date();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data,
    public dialogRef: MatDialogRef<BoardItemsComponent>,
    public boardService: BoardService, private paperService: PaperService,
    private fluteService: FluteService, private ectService: EctService, 
    private supplierService: SupplierService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.paperService.getPaperList().then(res => this.paperList = res as Paper[]);
    this.fluteService.getFluteList().then(res => this.fluteList = res as Flute[]);
    this.ectService.getEctList().then(res => this.ectList = res as Ect[]);
    this.supplierService.getSupplierList().then(res => this.supplierList = res as Supplier[]);
    /* this.boardService.getBoardListId("C",4,19,"K","IND","NA").then(idaltBoard => {
      this.maxAltBoard = Number(idaltBoard);
    }); */
    this.boardService.getBoardListId("C", 4, "23", 1, "K", "MI", "AI", "XX").subscribe(idaltBoard => {
      this.maxAltBoard = Number(idaltBoard)
    });
    //this.maxAltBoard = 1 + Number(this.boardService.getBoardListId(this.flutex, this.flutexx, this.flutexv, this.flutexw, this.flutexy, this.flutexz));
       
    //this.stateList = this.listState;

    if (this.data.boardItemIndex==null){
      this.boardService.boardList = {
      BoardID: '',
      SheetOrigin: "",
      SupplierID: 0,
      FluteID: 4, 
      BoardFlute: 'C', 
      ECT: '32',
      ColorPaperExt: 'K', 
      TypeStarch: 'AI',
      CoatingTo: 'XX', 
      Alternative: 0, 
      Coating: false, 
      FluteCoating: '', 
      ApprovedBoard: true, 
      PaperID01: '', 
      PaperID02: '',  
      PaperID03: '',  
      PaperID04: '',  
      PaperID05: '',  
      TypeMarket: 'MI',  
      EstimatedECT: 35,
      BoardDate : this.dateBoard,
      WasteCorrugatorSTD: 4, 
      WasteCorrugatorOtherApp: 6.5,
      Density: 0,
      TypeDensity: null, 
      Thickness: 4.12,
      TypePapers: '',
      Note: '',  
      BoardIDFilter: '',
      Liner10:'R',
      Medium10: 'R',
      Liner20: 'R',
      Medium20: '',
      Liner30: '',
      }
    }else {
        this.boardService.boardList = Object.assign({}, this.boardService.dataBoard[this.data.boardItemIndex]);
    };
    this.onTypePapers();
  };

  onSubmit (form: NgForm){
    //if (this.validateForm(form.value)){
      //this.validateForm(form.value);
      if (this.isValid == false){
        this.dialogRef.close();
      }else {
        if (this.validateForm(form.value)){
          //this.validateForm(form.value);
          //if (this.validateForm(form.value)){
            if(this.boardService.boardList.SheetOrigin == "Propia"){
              this.onGetTypePapers()
            } else {
              this.onGenerateTypePapers();
            };
          this.boardService.boardList.FluteID = +this.boardService.boardList.FluteID;
          this.boardService.boardList.SupplierID = +this.boardService.boardList.SupplierID;
          this.boardService.boardList.TypeDensity = +this.boardService.boardList.TypeDensity;
          this.boardService.boardList.BoardDate = this.dateBoard;
          if (this.data.boardItemIndex == null)
            this.ongetBoardListId();
          else
            this.onCalculatedDensity();
            this.boardService.putBoardID(this.boardService.boardList.BoardID, this.boardService.boardList).subscribe(res => {
              this.toastr.success('Actualización exitosa', 'Corrugated Quote.');
            });
          this.dialogRef.close();
        
        };
        this.isValid = true;
      };
  };
  
  onClose(){
    this.isValid = false;
    //this.dialogRef.close();
  }


  validateForm(formBoard: Board){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };
  
  onGetBoardFlute(ctrl){
    if (ctrl.selectedIndex == 0) {
      this.boardService.boardList.BoardFlute = '';
      this.boardService.boardList.Thickness = 0;
    } 
    else {
      this.boardService.boardList.BoardFlute = this.fluteList[ctrl.selectedIndex-1].FluteNomenclature;
      this.boardService.boardList.Thickness = this.fluteList.find(thickness => thickness.FluteID == this.boardService.boardList.FluteID).Thickness;
    };
  };

  onConcatenateBoard(){
    //REVISAR AL FINAL
    //this.boardService.boardList.Waste = +this.boardService.boardList.Waste;
    let supplierBoard: string;
    if (this.boardService.boardList.SheetOrigin == "Propia"){
      supplierBoard = "LGR"
    }else {
      supplierBoard = this.supplierList.find(supplierName => supplierName.SupplierID == this.boardService.boardList.SupplierID).SupplierName;
      supplierBoard = supplierBoard.substring(0,3).toUpperCase();
    };

    this.boardService.boardList.WasteCorrugatorSTD = +this.boardService.boardList.WasteCorrugatorSTD;
    this.boardService.boardList.WasteCorrugatorOtherApp = +this.boardService.boardList.WasteCorrugatorOtherApp;
    //this.boardService.boardList.BoardID = this.boardService.boardList.BoardFlute + '-' + this.boardService.boardList.ECT + '-' + this.boardService.boardList.ColorPaperExt + '-' + this.boardService.boardList.TypeMarket + '-' + this.boardService.boardList.TypeStarch + '-' + this.boardService.boardList.CoatingTo + '-' + this.boardService.boardList.Alternative;
    this.boardService.boardList.BoardID = this.boardService.boardList.BoardFlute + '-' + this.boardService.boardList.ECT + '-' + supplierBoard + '-' + this.boardService.boardList.ColorPaperExt + '-' + this.boardService.boardList.TypeMarket + '-' + this.boardService.boardList.TypeStarch + '-' + this.boardService.boardList.CoatingTo + '-' + this.boardService.boardList.Alternative;
  };

  ongetBoardListId(){
    let numAltBoard: number = 0;

    this.boardService.getBoardListId(this.boardService.boardList.BoardFlute, this.boardService.boardList.FluteID, this.boardService.boardList.ECT, this.boardService.boardList.SupplierID, this.boardService.boardList.ColorPaperExt, this.boardService.boardList.TypeMarket, this.boardService.boardList.TypeStarch, this.boardService.boardList.CoatingTo).subscribe(idaltBoard => {
      this.maxAltBoard = Number(idaltBoard); numAltBoard = this.maxAltBoard + 1; this.boardService.boardList.Alternative = numAltBoard; this.onConcatenateBoard(); this.onCalculatedDensity();
      this.boardService.postBoardCost().subscribe();
      this.boardService.postBoard().subscribe(res => {
        this.toastr.success('Grabado exitosamente!', 'Corrugated Quote.');
      });
    });
  };

  onCalculatedDensity(){
    if(this.boardService.boardList.SheetOrigin == "Propia"){
      let paper01density: number; let paper02density: number; let paper03density: number; 
      let paper04density: number; let paper05density: number; let kindFlute: string;
      let paper01RCT: number; let paper02CCT: number; let paper03RCT: number; 
      let paper04CCT: number; let paper05RCT: number;

      kindFlute = this.fluteList.find(f => f.FluteID == this.boardService.boardList.FluteID).FluteNomenclature;
    
      if (kindFlute.length == 1){
        paper01density = +this.paperList.find(a => a.PaperNomenclature == this.boardService.boardList.PaperID01).Weight;
        paper02density = +this.paperList.find(a => a.PaperNomenclature == this.boardService.boardList.PaperID02).Weight * +this.fluteList.find(a => a.FluteID == this.boardService.boardList.FluteID).FluteTakeUp;
        paper03density = +this.paperList.find(a => a.PaperNomenclature == this.boardService.boardList.PaperID03).Weight;
        paper04density = 0;
        paper05density = 0;
        paper01RCT = (0.0822 * +this.paperList.find(oliner => oliner.PaperNomenclature == this.boardService.boardList.PaperID01).RCT);
        paper02CCT = (0.2222 * +this.paperList.find(omedium => omedium.PaperNomenclature == this.boardService.boardList.PaperID02).CCT);
        paper03RCT = (0.2353 * +this.paperList.find(iliner => iliner.PaperNomenclature == this.boardService.boardList.PaperID03).RCT);
        paper04CCT = 0;
        paper05RCT = 0;
        this.boardService.boardList.EstimatedECT = parseFloat((0.94 + paper01RCT + paper02CCT + paper03RCT).toFixed(2))
      } 
      else 
      {
        let firstFlute: string; let secondFlute: string;
        firstFlute = kindFlute.slice(0,1); 
        secondFlute = kindFlute.slice(1,2)
        paper01density = +this.paperList.find(a => a.PaperNomenclature == this.boardService.boardList.PaperID01).Weight;
        paper02density = +this.paperList.find(b => b.PaperNomenclature == this.boardService.boardList.PaperID02).Weight * +this.fluteList.find(x => x.FluteNomenclature == firstFlute).FluteTakeUp;
        paper03density = +this.paperList.find(c => c.PaperNomenclature == this.boardService.boardList.PaperID03).Weight;
        paper04density = +this.paperList.find(d => d.PaperNomenclature == this.boardService.boardList.PaperID04).Weight * +this.fluteList.find(y => y.FluteNomenclature == secondFlute).FluteTakeUp;
        paper05density = +this.paperList.find(e => e.PaperNomenclature == this.boardService.boardList.PaperID05).Weight;
        paper01RCT = +this.paperList.find(oliner => oliner.PaperNomenclature == this.boardService.boardList.PaperID01).RCT / 6;
        paper02CCT = +this.paperList.find(omedium => omedium.PaperNomenclature == this.boardService.boardList.PaperID02).CCT * +this.fluteList.find(x => x.FluteNomenclature == firstFlute).FluteTakeUp / 6;
        paper03RCT = +this.paperList.find(sliner => sliner.PaperNomenclature == this.boardService.boardList.PaperID03).RCT / 6;
        paper04CCT = +this.paperList.find(imedium => imedium.PaperNomenclature == this.boardService.boardList.PaperID04).CCT * +this.fluteList.find(y => y.FluteNomenclature == secondFlute).FluteTakeUp / 6;
        paper05RCT = +this.paperList.find(iliner => iliner.PaperNomenclature == this.boardService.boardList.PaperID05).RCT / 6;
        this.boardService.boardList.EstimatedECT = parseFloat((paper01RCT + paper02CCT + paper03RCT + paper04CCT + paper05RCT).toFixed(2))
      };
      this.boardService.boardList.Density = parseFloat((paper01density + paper02density + paper03density + paper04density + paper05density).toFixed(2));
  }
    if(this.boardService.boardList.Density <= 450){
      this.boardService.boardList.TypeDensity = 0;
    } else if(this.boardService.boardList.Density > 450 && this.boardService.boardList.Density <= 700){
      this.boardService.boardList.TypeDensity = 1;
    } else if(this.boardService.boardList.Density > 700){
      this.boardService.boardList.TypeDensity = 2;
    };
  };

  onvalidateForm(){
    let kindFlute: string;

    if (this.boardService.boardList.FluteID == 0){
      this.getformFocus("FluteID", " una Flauta")
      kindFlute.length == 0;
    } else {
      kindFlute = this.fluteList.find(f => f.FluteID == this.boardService.boardList.FluteID).FluteNomenclature;
    }
    if (this.boardService.boardList.ECT == ""){
      this.getformFocus("ECT", " el ECT")
    };
    if (this.boardService.boardList.ColorPaperExt == ""){
      this.getformFocus("ColorPaperExt", " el Color Paper Exterior")
    };
    if (this.boardService.boardList.TypeStarch == ""){
      this.getformFocus("TypeStarch", " el Tipo de Adhesivo")
    };
    if (this.boardService.boardList.CoatingTo == ""){
      this.getformFocus("CoatingTo", " el Recubrimiento")
    };
    if (this.boardService.boardList.TypeMarket == ""){
      this.getformFocus("TypeMarket", "el Tipo de Mercado")
    };
    if(this.boardService.boardList.SheetOrigin == "Propia"){
      if (kindFlute.length <= 1){
        if (this.boardService.boardList.PaperID01 == ""){
          this.getformFocus("PaperID01", "un Liner exterior")
        };
        if (this.boardService.boardList.PaperID02 == ""){
          this.getformFocus("PaperID02", "un Papel Medium")
        };
        if (this.boardService.boardList.PaperID03 == ""){
          this.getformFocus("PaperID03", "un Liner interior")
        };
      }
      else 
      {
        if (this.boardService.boardList.PaperID01 == ""){
          this.getformFocus("PaperID01", "un Liner exterior")
        };
        if (this.boardService.boardList.PaperID02 == ""){
          this.getformFocus("PaperID02", "un Papel Medium")
        };
        if (this.boardService.boardList.PaperID03 == ""){
          this.getformFocus("PaperID03", "un Liner exterior")
        };
        if (this.boardService.boardList.PaperID04 == ""){
          this.getformFocus("PaperID04", "un Papel Medium")
        };
        if (this.boardService.boardList.PaperID05 == ""){
          this.getformFocus("PaperID05", "un Liner interior")
        };
      };
    };
    //this.onvalidateInk();
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, seleccione  ' + nameForm, 'Corrugated Quote.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };

  onGetCoating(){
    if(this.boardService.boardList.CoatingTo == "XX" || this.boardService.boardList.CoatingTo == ""){
      this.boardService.boardList.Coating = false;
    }else {
      this.boardService.boardList.Coating = true;
    };
  };

  onGetTypePapers(){
    let tpOne: string = ""; let tpTwo: string = ""; let tpThree: string = ""; let tpFour: string = ""; let tpFive: string = "";
    if(this.boardService.boardList.PaperID01 == "") {
      tpOne = "";
    } else {
      if(this.boardService.boardList.PaperID01.substring(0,2) == "WT") {
        tpOne = "B";
      } else if (this.boardService.boardList.PaperID01.substring(0,2) == "KL") {
        tpOne = "V";
      } else if (this.boardService.boardList.PaperID01.substring(0,2) == "KM") {
        tpOne = "V";
      } else {
        tpOne = "R";
      };
    };
    if(this.boardService.boardList.PaperID02 == "") {
      tpTwo = "";
    } else {
      if(this.boardService.boardList.PaperID02.substring(0,2) == "WT") {
        tpTwo = "B";
      } else if (this.boardService.boardList.PaperID02.substring(0,2) == "KL") {
        tpTwo = "V";
      } else if (this.boardService.boardList.PaperID02.substring(0,2) == "KM") {
        tpTwo = "V";
      } else {
        tpTwo = "R";
      };
    };
    if(this.boardService.boardList.PaperID03 == "") {
      tpThree = "";
    } else {
      if(this.boardService.boardList.PaperID03.substring(0,2) == "WT") {
        tpThree = "B";
      } else if (this.boardService.boardList.PaperID03.substring(0,2) == "KL") {
        tpThree = "V";
      } else if (this.boardService.boardList.PaperID03.substring(0,2) == "KM") {
        tpThree = "V";
      } else {
        tpThree = "R";
      };
    };
    if(this.boardService.boardList.PaperID04 == "" || this.boardService.boardList.PaperID04 == null) {
      tpFour = "";
    } else {
      if(this.boardService.boardList.PaperID04.substring(0,2) == "WT") {
        tpFour = "B";
      } else if (this.boardService.boardList.PaperID04.substring(0,2) == "KL") {
        tpFour = "V";
      } else if (this.boardService.boardList.PaperID04.substring(0,2) == "KM") {
        tpFour = "V";
      } else {
        tpFour = "R";
      };
    };
    if(this.boardService.boardList.PaperID05 == "" || this.boardService.boardList.PaperID05 == null) {
      tpFive = "";
    } else {
      if(this.boardService.boardList.PaperID05.substring(0,2) == "WT") {
        tpFive = "B";
      } else if (this.boardService.boardList.PaperID05.substring(0,2) == "KL") {
        tpFive = "V";
      } else if (this.boardService.boardList.PaperID05.substring(0,2) == "KM") {
        tpFive = "V";
      } else {
        tpFive = "R";
      };
    };
    this.boardService.boardList.TypePapers = tpOne + tpTwo + tpThree + tpFour + tpFive;
  };

  onGetSupplier(){
    if(this.boardService.boardList.SheetOrigin == "Propia"){
      this.boardService.boardList.SupplierID = 1;
    }else {
      this.boardService.boardList.SupplierID = 0;
    };
  };

//test for generate typepapers when use sheet from other suppliers
  onGenerateTypePapers(){
    if (this.boardService.boardList.BoardFlute.length == 1){
      this.boardService.boardList.TypePapers = this.boardService.boardList.Liner10 + this.boardService.boardList.Medium10 + this.boardService.boardList.Liner20;
    } else {
      this.boardService.boardList.TypePapers = this.boardService.boardList.Liner10 + this.boardService.boardList.Medium10 + this.boardService.boardList.Liner20 + this.boardService.boardList.Medium20 + this.boardService.boardList.Liner30;
    };
  };

  onTypePapers(){
    if(this.boardService.boardList.BoardID != null){
      if (this.boardService.boardList.BoardFlute.length == 1){
        this.boardService.boardList.Liner10 = this.boardService.boardList.TypePapers.substring(0,1); 
        this.boardService.boardList.Medium10 = this.boardService.boardList.TypePapers.substring(1,2); 
        this.boardService.boardList.Liner20 = this.boardService.boardList.TypePapers.substring(2,3);  
        this.boardService.boardList.Medium20 = "";
        this.boardService.boardList.Liner30 = "";
      } else {
        this.boardService.boardList.Liner10 = this.boardService.boardList.TypePapers.substring(0,1); 
        this.boardService.boardList.Medium10 = this.boardService.boardList.TypePapers.substring(1,2); 
        this.boardService.boardList.Liner20 = this.boardService.boardList.TypePapers.substring(2,3); 
        this.boardService.boardList.Medium20 = this.boardService.boardList.TypePapers.substring(3,4); 
        this.boardService.boardList.Liner30 = this.boardService.boardList.TypePapers.substring(4,5); 
      };
    };
  };
};
