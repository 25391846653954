<!--h3>Buscar Tipo de Transporte:</h3-->
<label><b>{{ 'trucktitle' | translate }}</b></label>
<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <input name="TruckIDFilter" id="TruckIDFilter" class="form-control" placeholder="{{ 'trucksearch' | translate }}" 
        (keyup)="onFilterTruckName($event.target.value)">
    </div>
</div>
<table class="table table-hover">
    <thead class="thead-light">
        <th>{{ 'truckid' | translate }}</th>
        <th>{{ 'trucktype' | translate }}</th>
        <th style="text-align: center;">{{ 'trucklong' | translate }}</th>
        <th style="text-align: center;">{{ 'truckwidth' | translate }}</th>
        <th style="text-align: center;">{{ 'truckheight' | translate }}</th>
        <th style="text-align: center;">{{ 'truckcapacity' | translate }}</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditTruckTypeItem(null, dataTruckType.dataTruckType.TruckID)"><i class="fa fa-plus-square"></i>&nbsp; {{ 'btnnew' | translate }}</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of dataTruckType.dataTruckType; let i=index;">
            <th (click)="AddOrEditTruckTypeItem(i, item.TruckID)">{{item.TruckID}}</th>
            <td>{{item.TruckName}}</td>
            <td style="text-align: center;">{{item.Length}}</td>
            <td style="text-align: center;">{{item.Width}}</td>
            <td style="text-align: center;">{{item.Height}}</td>
            <td style="text-align: center;">{{item.CapacityM3}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditTruckTypeItem(i, item.TruckID)"><i class="fa fa-pencil"></i></a>
                <!-- <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteWorkstationItem(item.MachineID)"><i class="fa fa-trash"></i></a> -->
            </td>
        </tr>
    </tbody>
</table>