import { Component, OnInit,EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Destiny } from 'src/app/shared/destiny.model';
import { DestinyService } from 'src/app/shared/destiny.service';
import { DestinyItemsComponent } from './destiny-items.component';
import { DialogConfirm } from 'src/app/quotes/quote/confirm/dialog-confirm-component';

@Component({
  selector: 'app-destiny',
  templateUrl: './destiny.component.html',
  styles: []
})
export class DestinyComponent implements OnInit {
  //paginación declaracion de variables
  itemsPerPage=12;
  currentPage=1;
  totalPages = 0;
  totalItems=0;
  data1;
  totales;
  pages: number [] = [];
  @Output() onClick: EventEmitter<number> = new EventEmitter();//captura el evento click y se mueva

  constructor(
    private dialog: MatDialog, 
    public dataDestiny: DestinyService, 
    private router: Router, 
    private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dataDestiny.getDestinyList().then(res => this.dataDestiny.dataDestiny = res as Destiny);
  }
 //paginación funciones
  get paginatedData(){    
    this.data1= this.dataDestiny.dataDestiny;
    console.log(this.data1);  
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.totalItems = this.data1.length;
      return this.data1.slice(start,end);
  }

  ngAfterViewInit(){
    this.updateItems();
  }

  updateItems(){
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({length: this.totalPages}, (_, i) => i + 1);
       //console.log(this.pages);
  }

  pageClicked(page: number){
    if(page > this.totalPages) return;
    this.onClick.emit(page);
    this.changePage(page);
  }

  changePage(page: number){
  this.currentPage=page;
  }

  refreshList(){
    this.dataDestiny.getDestinyList().then(res => this.dataDestiny.dataDestiny = res as Destiny);
  };

  AddOrEditDestinyItem(destinyItemIndex, DestinyID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="100%";
    dialogConfig.height ="50%";
    dialogConfig.data = { destinyItemIndex, DestinyID };
    this.dialog.open(DestinyItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  onDeleteDestinyItem(id: number){
    this.confirmDisplayDelete(id);
    /*if (confirm('Estas seguro de querer eliminar el Destino seleccionado?')) {
      this.dataDestiny.deleteDestiny(id).then(() => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente', 'Corrugated Quote.');
      }, () =>{
          this.toastr.error('El Destino seleccionado no se puede eliminar, tiene registros activos con otras tablas', 'Corrugated Quote.');
      });
    };
    /* if (confirm('Estas seguro de querer eliminar el registro?')){
      this.dataDestiny.deleteDestiny(id).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Corrugated Quote.');
      });
    }; */
  };

  confirmDisplayDelete(id:number): void {
    this.dialog
      .open(DialogConfirm, {
        data: '¿Estas seguro de querer eliminar el Destino seleccionado?',
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
            this.dataDestiny.deleteDestiny(id).then(res => {
            this.refreshList();
            this.toastr.warning('Borrado exitoso!', 'Corrugated Quote.');
          })
        }
      });
  };

  onFilterDestinyName(destinyName: string){
    this.dataDestiny.getDestinyListId(destinyName).then(res => this.dataDestiny.dataDestiny = res as Destiny);
    this.totalItems = this.data1.length;
    this.currentPage = 1;
    this.updateItems();
  };
}

