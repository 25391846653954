import { Component, OnInit,EventEmitter,Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Supplier } from 'src/app/shared/supplier.model';
import { SupplierService } from 'src/app/shared/supplier.service';
import { SupplierItemsComponent } from './supplier-items.component';
import { DialogConfirm } from 'src/app/quotes/quote/confirm/dialog-confirm-component';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styles: []
})
export class SupplierComponent implements OnInit {
  //paginación declaracion de variables
  itemsPerPage=12;
  currentPage=1;
  totalPages = 0;
  totalItems=0;
  data1;
  totales;
  pages: number [] = [];
  @Output() onClick: EventEmitter<number> = new EventEmitter();//captura el evento click y se mueva

  constructor(
    private dialog: MatDialog, 
    public dataSupplier: SupplierService, 
    private router: Router, 
    private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dataSupplier.getSupplierList().then(res => this.dataSupplier.dataSupplier = res as Supplier);
  }

  //paginación funciones
  get paginatedData(){    
    this.data1= this.dataSupplier.dataSupplier;
    console.log(this.data1);  
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.totalItems = this.data1.length;
      return this.data1.slice(start,end);
  }

  ngAfterViewInit(){
    this.updateItems();
  }

  updateItems(){
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({length: this.totalPages}, (_, i) => i + 1);
       //console.log(this.pages);
  }

  pageClicked(page: number){
    if(page > this.totalPages) return;
    this.onClick.emit(page);
    this.changePage(page);
  }

  changePage(page: number){
  this.currentPage=page;
  }
  refreshList(){
    this.dataSupplier.getSupplierList().then(res => this.dataSupplier.dataSupplier = res as Supplier);
  };

  AddOrEditSupplierItem(supplierItemIndex, SupplierID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="70%";
    dialogConfig.height ="60%";
    dialogConfig.data = { supplierItemIndex, SupplierID };
    this.dialog.open(SupplierItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };
  confirmDisplayDelete(id:number): void {
    this.dialog
      .open(DialogConfirm, {
        data: '¿Estas seguro de querer eliminar al proveedor?',
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.dataSupplier.deleteSupplier(id).then(res => {
            this.refreshList();
            this.toastr.warning('Borrado exitoso!', 'Corrugated Quote.');
          })
        }
      });
  };
  onDeleteSupplierItem(id: number){
    this.confirmDisplayDelete(id);
    /*if (confirm('Estas seguro de querer eliminar el registro?')) {
      this.dataSupplier.deleteSupplier(id).then(() => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente', 'Corrugated Quote.');
      }, () =>{
          this.toastr.error('El Proveedor seleccionado no se puede eliminar, tiene registros activos con otras tablas', 'Corrugated Quote.');
      });
    };
    /* if (confirm('Estas seguro de querer eliminar el registro?')){
      this.dataSupplier.deleteSupplier(id).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Corrugated Quote.');
      });
    }; */
  };

  onFilterSupplierName(supplierName: string){
    this.dataSupplier.getSupplierListId(supplierName).then(res => this.dataSupplier.dataSupplier = res as Supplier);
    this.updateItems();
  };
}
