import {Component, EventEmitter, OnInit, Output } from '@angular/core';//agregamos event y outp para la paginación
import { QuoteService } from '../shared/quote.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { QuoterepService } from '../shared/quoterep.service';
import { QuoteprofitService } from '../shared/quoteprofit.service';
import { QuotebomService } from '../shared/quotebom.service';
import { DialogConfirm } from "../quotes/quote/confirm/dialog-confirm-component";
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styles: []
})
export class QuotesComponent implements OnInit {
    quoteList;
    //paginación declaracion de variables
    itemsPerPage=12;
    currentPage=1;
    totalPages = 0;
    totalItems=0;
    data1;
    totales;
    pages: number [] = [];
    @Output() onClick: EventEmitter<number> = new EventEmitter();//captura el evento click y se mueva

//isSheet: boolean = true;

  constructor(private service: QuoteService, private router: Router,
    private quoterepService: QuoterepService, private toastr: ToastrService,
    private quoteprofitService: QuoteprofitService, private quotebomService: QuotebomService, private dialog: MatDialog
  ) { }
    

  ngOnInit(): void {
  this.refreshList();
    if(this.service.openNewQuote == true){
      this.confirmDisplayQuote();
      this.service.openNewQuote = false;
  }
  };
//paginación funciones
  get paginatedData(){
    this.data1 = JSON.stringify(this.quoteList);
    this.data1 = JSON.parse(this.data1);
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.totalItems = this.data1.length;
      return this.data1.slice(start,end);
  }

  ngAfterViewInit(){
  this.updateItems();
  }

  updateItems(){
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({length: this.totalPages}, (_, i) => i + 1);
    //console.log(this.pages);
  }

  pageClicked(page: number){
    if(page > this.totalPages) return;
    this.onClick.emit(page);
    this.changePage(page);
  }

  changePage(page: number){
  this.currentPage=page;
  }

  /* refreshList(){
    this.service.getQuoteList().then(res => this.quoteList = res);
  } */

  refreshList(){
    document.getElementById("CustomerFilter").focus();
    var formCustomer = <HTMLInputElement> document.getElementById("CustomerFilter");
    
    if ( formCustomer.value == '' || formCustomer.value == undefined){
      this.service.getQuoteList().then(res => this.quoteList = res, );
      //.dataBoard.getBoardList().then(res => this.dataBoard.dataBoard = res as Board);
    } else {
      this.service.getQuoteCustomerFilter(formCustomer.value).then(res => this.quoteList = res);
    };
  };

  refreshListSKU(){
    //this.service.getQuoteList().then(res => this.quoteList = res);
    document.getElementById("CustomerSKUFilter").focus();
    var formCustomerSKU = <HTMLInputElement> document.getElementById("CustomerSKUFilter");
    
    if ( formCustomerSKU.value == '' || formCustomerSKU.value == undefined){
      this.service.getQuoteList().then(res => this.quoteList = res);
      //.dataBoard.getBoardList().then(res => this.dataBoard.dataBoard = res as Board);
    } else {
      this.service.getQuoteSimboloFilter(formCustomerSKU.value).then(res => this.quoteList = res);
    };
  };

  openForEdit(quoteID: number){
    this.service.NewOrEdit = false;
    this.service.viewBoxSheetBotton = false;
    this.router.navigate(['/quote/edit/' + quoteID]);
  }
  
  onQuoteDelete(id: number){
    this.confirmDisplayDelete(id)
    /*if (confirm('Estas seguro de querer borrar el registro??')){
      this.service.deleteQuote(id).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitoso!', 'Corrugated Quote.');
      });
    };*/
  };

  onQuoteRep(quoteID: number){
    this.quoterepService.getQuoteRepId(quoteID).subscribe(response=>{
      let fileName = "Quote_" + quoteID;
      let blob: Blob = response.body as Blob;
      let a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  };

  onQuoteBOM(quoteID: number){
    this.quotebomService.getQuoteBOMId(quoteID).subscribe(response=>{
      let fileName = "QuoteBOM_" + quoteID;
      let blob: Blob = response.body as Blob;
      let a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  };

  onQuoteProfit(quoteID: number){
    this.quoteprofitService.getQuoteProfitId(quoteID).subscribe(response=>{
      let fileName = "QuoteProfit_" + quoteID;
      let blob: Blob = response.body as Blob;
      let a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  };

  onNewOrEdit(){
    this.service.NewOrEdit = true;
    this.service.viewBoxSheetBotton = true;
  }

  onFilterCustomer(customerID: string){
    this.refreshList()
    //this.service.getQuoteCustomerFilter(customerID).then(res => this.quoteList = res);
    //paginación aplicada al filtro
    this.totalItems = this.data1.length;
    this.currentPage = 1;
    this.updateItems();    
  };

  onFilterCustomerSKU(customerSKU: string){
    this.refreshListSKU();
    //this.service.getQuoteSimboloFilter(customerSKU).then(res => this.quoteList = res);
    //paginación
    this.totalItems = this.data1.length;
    this.currentPage = 1;
    this.updateItems();
  };
 
  confirmDisplayQuote(): void {
    this.dialog
      .open(DialogConfirm, {
        data: '¿Desaes abrir la Cotización copiada?',
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.router.navigate(['./quote/edit/' + this.service.numOpenNewQuote])
        }else{
          this.router.navigate(['./quotes']);
        }
      });  
  }

  confirmDisplayDelete(id:number): void {
    this.dialog
      .open(DialogConfirm, {
        data: '¿Estas seguro de querer borrar la cotización?',
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.service.deleteQuote(id).then(res => {
            this.refreshList();
            this.toastr.warning('Borrado exitoso!', 'Corrugated Quote.');
          })
        }
      });
  }
}
