import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Freight } from 'src/app/shared/freight.model';
import { FreightService } from 'src/app/shared/freight.service';
import { FreightItemsComponent } from './freight-items.component';
import { DialogConfirm } from 'src/app/quotes/quote/confirm/dialog-confirm-component';

@Component({
  selector: 'app-freight',
  templateUrl: './freight.component.html',
  styles: []
})
export class FreightComponent implements OnInit {

  //paginación declaracion de variables
  itemsPerPage=12;
  currentPage=1;
  totalPages = 0;
  totalItems=0;
  data1;
  totales;
  pages: number [] = [];
  @Output() onClick: EventEmitter<number> = new EventEmitter();//captura el evento click y se mueva

  constructor(
    private dialog: MatDialog, 
    public dataFreight: FreightService, 
    private router: Router, 
    private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dataFreight.getFreightTotalList().then(res => this.dataFreight.dataFreight = res as Freight);
  }
 //paginación funciones
  get paginatedData(){    
    this.data1= this.dataFreight.dataFreight;
    console.log(this.data1);  
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.totalItems = this.data1.length;
      return this.data1.slice(start,end);
  }

  ngAfterViewInit(){
    this.updateItems();
  }

  updateItems(){
   
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({length: this.totalPages}, (_, i) => i + 1);
       //console.log(this.pages);
  }

  pageClicked(page: number){
    if(page > this.totalPages) return;
    this.onClick.emit(page);
    this.changePage(page);
  }

  changePage(page: number){
    this.currentPage=page;
  }
  refreshList(){
    this.dataFreight.getFreightTotalList().then(res => this.dataFreight.dataFreight = res as Freight);
  };

  AddOrEditFreightItem(freightItemIndex, FreightID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="100%";
    dialogConfig.height ="40%";
    dialogConfig.data = { freightItemIndex, FreightID };
    this.dialog.open(FreightItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  confirmDisplayDelete(id:number): void {
    this.dialog
      .open(DialogConfirm, {
        data: '¿Estas seguro de querer eliminar el Flete seleccionado?',
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.dataFreight.deleteFreight(id).then(res => {
            this.refreshList();
            this.toastr.warning('Borrado exitoso!', 'Corrugated Quote.');
          })
        }
      });
  };

  onDeleteFreightItem(id: number){
    this.confirmDisplayDelete(id);
    /*if (confirm('Estas seguro de querer eliminar el Flete seleccionado?')) {
      this.dataFreight.deleteFreight(id).then(() => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente', 'Corrugated Quote.');
      }, () =>{
          this.toastr.error('El Flete seleccionado no se puede eliminar, tiene registros activos con otras tablas', 'Corrugated Quote.');
      });
    };
    /* if (confirm('Estas seguro de querer eliminar el registro?')){
      this.dataFreight.deleteFreight(id).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Corrugated Quote.');
      });
    }; */
  };


}
