import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuotesComponent } from './quotes/quotes.component';
import { QuoteComponent } from './quotes/quote/quote.component';
import { QuoteItemsComponent } from './quotes/quote-items/quote-items.component';
import { QuoteService } from './shared/quote.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'; //HTTP: cacha lo que cae en la liga
import { ToastrModule } from 'ngx-toastr';
import { QuoteSheetComponent } from './quotes/quote-items/quote-sheet.component';
import { NgxMaskModule } from 'ngx-mask';
import { CustomerComponent } from './maintenance/customer/customer.component';
import { CustomerItemsComponent } from './maintenance/customer/customer-items.component'
import { CustomerService } from './shared/customer.service';
import { BoardComponent } from './maintenance/board/board.component';
import { BoardItemsComponent } from './maintenance/board/board-items.component';
import { BoardcostItemsComponent } from './maintenance/board/boardcost-items.component';
import { PaperComponent } from './maintenance/paper/paper.component';
import { PaperItemsComponent } from './maintenance/paper/paper-items.component';
import { BoardService } from './shared/board.service';
import { BoardcostService } from './shared/boardcost.service';
import { PaperService } from './shared/paper.service';
import { SupplierComponent } from './maintenance/supplier/supplier.component';
import { SupplierItemsComponent } from './maintenance/supplier/supplier-items.component';
import { SupplierService } from './shared/supplier.service';
import { AuxiliaryComponent } from './maintenance/auxiliary/auxiliary.component';
import { AuxiliaryItemsComponent } from './maintenance/auxiliary/auxiliary-items.component';
import { AuxiliaryService } from './shared/auxiliary.service';
import { WorkstationComponent } from './maintenance/workstation/workstation.component';
import { WorkstationItemsComponent } from './maintenance/workstation/workstation-items.component';
import { WorkstationService } from './shared/workstation.service';
import { InkComponent } from './maintenance/ink/ink.component';
import { InkItemsComponent } from './maintenance/ink/ink-items.component';
import { QuotehomeComponent } from './quotehome/quotehome.component';
import { UpdatepapercostComponent } from './maintenance/paper/updatepapercost.component';
import { FreightComponent } from './maintenance/freight/freight.component';
import { FreightItemsComponent } from './maintenance/freight/freight-items.component';
import { FreightService } from './shared/freight.service';
import { DestinyComponent } from './maintenance/destiny/destiny.component';
import { DestinyItemsComponent } from './maintenance/destiny/destiny-items.component';
import { DestinyService } from './shared/destiny.service';
import { QuoterepService } from './shared/quoterep.service';
import { PalletComponent } from './maintenance/pallet/pallet.component';
import { PalletItemsComponent } from './maintenance/pallet/pallet-items.component';
import { CostrateComponent } from './maintenance/costrate/costrate.component';
import { CostrateItemsComponent } from './maintenance/costrate/costrate-items.component';
import { TrucktypeComponent } from './maintenance/trucktype/trucktype.component';
import { TrucktypeItemComponent } from './maintenance/trucktype/trucktype-item.component';
import { QuoteKitsComponent } from './quotes/quote-items/quote-kits.component';

// Import MSAL and MSAL browser libraries. for login with app
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

//Import traslate for multilanguaje
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'; 

export function translateHttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');

}

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
    declarations: [
        AppComponent,
        QuotesComponent,
        QuoteComponent,
        QuoteItemsComponent,
        QuoteSheetComponent,
        CustomerComponent,
        CustomerItemsComponent,
        BoardComponent,
        BoardItemsComponent,
        BoardcostItemsComponent,
        PaperComponent,
        PaperItemsComponent,
        SupplierComponent,
        SupplierItemsComponent,
        AuxiliaryComponent,
        AuxiliaryItemsComponent,
        WorkstationComponent,
        WorkstationItemsComponent,
        InkComponent,
        InkItemsComponent,
        QuotehomeComponent,
        UpdatepapercostComponent,
        FreightComponent,
        FreightItemsComponent,
        DestinyComponent,
        DestinyItemsComponent,
        PalletComponent,
        PalletItemsComponent,
        CostrateComponent,
        CostrateItemsComponent,
        TrucktypeComponent,
        TrucktypeItemComponent,
        QuoteKitsComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule,
        MatDialogModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: translateHttpLoaderFactory,
              deps: [HttpClient]
            }
          }),
        NgxMaskModule.forRoot({ dropSpecialCharacters: false }),
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: "4896122e-98b6-4d3e-a6c6-ff8ebe5e62ae",
                authority: "https://login.microsoftonline.com/5064b2d4-2ca6-4ddc-bff8-23343ae402d8/",
                //redirectUri: "http://localhost:4200", // This is your redirect URI local
                redirectUri: "https://cotizador.cartonero.digital", // en azure
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            },
        }), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ["user.read"],
            },
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([
                ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
            ]),
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        MsalGuard, QuoteService, CustomerService, BoardService, BoardcostService, PaperService, SupplierService, AuxiliaryService, WorkstationService, FreightService, DestinyService, QuoterepService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
