import { Component, OnInit,EventEmitter,Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Customer } from '../../shared/customer.model';
import { CustomerService } from '../../shared/customer.service';
import { CustomerItemsComponent } from './customer-items.component';
import { DialogConfirm } from "../../quotes/quote/confirm/dialog-confirm-component";


@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styles: []
})

export class CustomerComponent implements OnInit {
  //paginación declaracion de variables
  itemsPerPage=12;
  currentPage=1;
  totalPages = 0;
  totalItems=0;
  data1;
  totales;
  pages: number [] = [];
  @Output() onClick: EventEmitter<number> = new EventEmitter();//captura el evento click y se mueva

  constructor(
    private dialog: MatDialog,
    public dataCustomer: CustomerService, 
    private router: Router, 
    private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.dataCustomer.getCustomerList().then(res => this.dataCustomer.dataCustomer = res as Customer);
  }
  //paginación funciones
  get paginatedData(){    
    this.data1= this.dataCustomer.dataCustomer;
    console.log(this.data1);  
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.totalItems = this.data1.length;
      return this.data1.slice(start,end);
  }

  ngAfterViewInit(){
  this.updateItems();
  }

  updateItems(){
   
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({length: this.totalPages}, (_, i) => i + 1);
       //console.log(this.pages);
  }

  pageClicked(page: number){
    if(page > this.totalPages) return;
    this.onClick.emit(page);
    this.changePage(page);
  }

  changePage(page: number){
  this.currentPage=page;
  }
  refreshList(){
    this.dataCustomer.getCustomerList().then(res => this.dataCustomer.dataCustomer = res as Customer);
  };

  AddOrEditCustomerItem(customerItemIndex, CustomerID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="70%";
    dialogConfig.height ="70%";
    dialogConfig.data = { customerItemIndex, CustomerID };
    this.dialog.open(CustomerItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  confirmDisplayDelete(id:number): void {
    this.dialog
      .open(DialogConfirm, {
        data: '¿Estas seguro de querer eliminar el Cliente?',
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.dataCustomer.deleteCustomer(id).then(res => {
            this.refreshList();
            this.toastr.warning('Borrado exitoso!', 'Corrugated Quote.');
          })
        }
      });
  };

  onDeleteCustomerItem(id: number){
    this.confirmDisplayDelete(id);
    /*if (confirm('Estas seguro de querer eliminar el registro?')) {
      this.dataCustomer.deleteCustomer(id).then(() => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente', 'Corrugated Quote.');
      }, () =>{
          this.toastr.error('El cliente seleccionado no se puede eliminar, tiene registros activos con otras tablas', 'Corrugated Quote.');
      });
    };

    /* if (confirm('Estas seguro de querer eliminar el registro?') == true) {
      this.dataCustomer.deleteCustomer(id).then(res =>{
        this.catchError = 1; 
        this.refreshList();
        this.toastr.warning('Borrado exitosamente', 'Corrugated Quote.');
      });
    } else {
      this.catchError = 1;
    };
        if (this.catchError == 0){
          this.toastr.error('El cliente seleccionado no se puede eliminar, tiene registros activos con otras tablas', 'Corrugated Quote.');
        }; */
  };

  onFilterCustomerName(customerName: string){
    this.dataCustomer.getCustomerListId(customerName).then(res => this.dataCustomer.dataCustomer = res as Customer);
    this.updateItems();
  };
}
